<template>
  <Layout>
    <template v-slot:content>
      <div class="px-4 md:px-20 py-8">
        <div class="w-full md:w-3/4 mx-auto flex justify-center">
          <div class="overflow-y-auto md:w-2/3">
            <div v-for="feed in feeds" :key="feed._id">
              <FeedItem :feed="feed" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/Layouts/Layout";
import FeedItem from "@/components/FeedItem";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Feed",
  components: { Layout, FeedItem },
  setup() {
    const feeds = ref([]);
    const router = useRouter();

    onMounted(async () => (feeds.value = await getFeed()));

    const getFeed = async () => {
      try {
        const { data } = await window.axios.get("/feed");
        return data;
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    return {
      feeds,
    };
  },
};
</script>